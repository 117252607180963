
import RecordingsWidget from "@/components/recordings/widget/RecordingsWidget"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useAccountStore } from "@/stores/account"

export default {
  components: {
    RecordingsWidget,
  },
  layout: "widget",
  async asyncData({ query, redirect }) {
    const { api_id: apiId, api_key: apiKey } = query
    const isPrivate = query.private === "true"
    useAccountStore().isLiveViewWidget = true
    try {
      const { cameras } = await EvercamApi.cameras.getCameraById(query.camera, {
        params: {
          apiId,
          apiKey,
        },
      })

      return {
        camera: cameras[0],
        cameraId: query.camera,
        private: isPrivate,
        refreshRate: parseInt(query.refresh * 1000),
        apiId,
        apiKey,
        allowPlayPause: query.playpause === "true",
        allowZoom: query.zoom === "true",
        allowFullscreen: query.fullscreen === "true",
        allowDownload: query.download === "true",
      }
    } catch (error) {
      console.log(error)
      redirect("/v2/users/signin")

      return
    }
  },
}
